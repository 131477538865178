import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
          <path d="M5766 6658 c10 -13 23 -26 28 -29 5 -4 5 -13 0 -20 -6 -12 -5 -12 8
-1 13 10 17 10 21 -2 4 -9 2 -18 -3 -21 -6 -4 -4 -18 5 -36 10 -17 20 -27 27
-23 7 5 9 2 6 -8 -4 -8 5 -34 18 -58 13 -23 24 -45 24 -49 0 -5 -44 0 -97 9
-203 37 -291 43 -563 37 -146 -3 -282 -9 -302 -15 -29 -8 -38 -7 -42 4 -3 8
-1 14 4 14 11 0 54 80 43 80 -5 0 -2 4 6 9 8 5 -18 8 -65 7 -44 0 -81 -4 -84
-7 -3 -4 -41 -10 -85 -15 -77 -9 -80 -10 -78 -34 2 -21 1 -22 -8 -8 -5 10 -9
24 -8 30 1 7 0 22 -1 33 -2 17 -18 -35 -22 -72 -1 -7 -6 -13 -11 -13 -5 0 -6
8 -2 18 28 64 -2 67 -43 3 -19 -29 -28 -50 -21 -47 8 4 6 1 -4 -7 -10 -8 -48
-68 -86 -133 -37 -66 -114 -197 -169 -292 -56 -95 -107 -182 -113 -193 -6 -12
21 4 68 39 160 122 415 257 533 282 19 4 69 15 110 25 147 33 266 44 457 45
199 0 283 -7 443 -40 346 -71 587 -170 799 -329 41 -31 83 -62 93 -70 11 -8
-11 37 -48 100 -36 63 -116 200 -177 306 -61 105 -114 190 -118 187 -4 -2 -6
2 -5 9 1 16 -116 217 -127 217 -4 0 -6 -4 -3 -8 2 -4 0 -14 -5 -22 -7 -12 -8
-11 -6 5 1 11 6 25 10 31 4 7 0 17 -8 24 -10 9 -15 9 -15 1 0 -14 -33 -14 -48
1 -8 8 -15 8 -27 -2 -10 -8 -48 -13 -105 -14 -50 0 -90 -4 -90 -8 0 -21 -36
-3 -77 37 -47 47 -70 61 -37 23z m124 -107 c0 -9 -38 -12 -44 -3 -2 4 7 8 20
8 13 1 24 -1 24 -5z m-971 -14 c6 10 11 13 11 6 0 -6 -5 -15 -11 -18 -6 -4 -8
-14 -5 -24 5 -13 4 -13 -8 2 -10 13 -15 15 -19 5 -2 -7 -4 -1 -3 15 1 23 3 25
13 11 10 -14 12 -14 22 3z m-337 -19 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2
-3 -1 -11 -7 -17z m1319 -1 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11
-7 -18z m-1331 -21 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21
13z"/>
<path d="M5796 6665 c4 -8 11 -15 16 -15 6 0 5 6 -2 15 -7 8 -14 15 -16 15 -2
0 -1 -7 2 -15z"/>
<path d="M6100 6673 c0 -6 8 -14 18 -18 28 -13 43 -14 36 -3 -3 5 -9 7 -12 4
-3 -3 -14 1 -24 11 -10 9 -18 11 -18 6z"/>
<path d="M6087 6649 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M4983 6615 c0 -8 4 -12 9 -9 4 3 8 9 8 15 0 5 -4 9 -8 9 -5 0 -9 -7
-9 -15z"/>
<path d="M5175 6065 c-550 -77 -1047 -404 -1319 -867 l-46 -78 198 0 197 0 28
53 c92 175 304 400 509 539 158 108 413 215 598 252 205 41 437 45 624 11 49
-8 90 -14 93 -12 4 5 -107 40 -208 66 -191 49 -475 64 -674 36z"/>
<path d="M5450 5885 c-322 -52 -596 -186 -815 -399 -35 -34 -41 -43 -20 -30
138 84 293 147 470 190 102 25 126 27 325 28 235 1 298 -8 474 -66 230 -75
420 -192 601 -370 l120 -118 198 0 198 0 -25 43 c-98 166 -262 344 -424 460
-181 130 -408 221 -652 262 -102 18 -344 17 -450 0z"/>
<path d="M2970 4595 c0 -3 -9 -38 -19 -78 -11 -39 -47 -180 -81 -312 -33 -132
-63 -248 -66 -257 -5 -16 4 -18 84 -18 l90 0 7 50 7 51 86 -3 87 -3 9 -47 8
-48 89 0 c49 0 89 3 89 6 0 3 -22 92 -49 197 -28 106 -64 246 -81 312 -16 66
-33 128 -36 138 -5 15 -19 17 -115 17 -60 0 -109 -2 -109 -5z m138 -308 c12
-57 22 -109 22 -115 0 -8 -19 -12 -50 -12 -33 0 -50 4 -50 12 0 25 45 218 51
218 3 0 15 -47 27 -103z"/>
<path d="M3619 4584 c-66 -27 -69 -42 -69 -316 0 -261 3 -274 53 -311 23 -18
46 -22 145 -25 133 -5 187 6 223 44 21 22 24 37 28 115 l3 90 -88 -3 -89 -3
-3 -63 -3 -63 -42 3 -42 3 -3 213 -2 212 45 0 45 0 0 -65 0 -65 90 0 90 0 0
83 c0 101 -19 140 -77 156 -58 16 -261 13 -304 -5z"/>
<path d="M4284 4586 c-63 -28 -64 -33 -64 -322 0 -261 0 -261 24 -287 33 -35
84 -47 201 -46 184 1 219 27 220 162 l0 82 -87 3 -87 3 -3 -63 -3 -63 -39 -3
c-24 -2 -42 2 -47 10 -5 7 -9 99 -9 203 0 105 4 196 9 203 5 8 23 12 47 10
l39 -3 3 -62 3 -63 91 0 91 0 -5 90 c-6 100 -17 122 -75 146 -44 19 -266 18
-309 0z"/>
<path d="M4890 4310 c0 -323 2 -336 62 -361 23 -10 75 -14 168 -14 149 0 192
12 210 61 6 14 10 155 10 315 l0 289 -85 0 -85 0 0 -254 c0 -140 -3 -261 -6
-270 -9 -23 -89 -22 -98 1 -4 9 -6 129 -6 267 l1 251 -85 3 -86 3 0 -291z"/>
<path d="M5580 4265 l0 -335 90 0 90 0 0 120 c0 66 3 120 8 120 4 0 31 -54 61
-120 l54 -120 93 0 c52 0 94 2 94 5 0 3 -26 58 -59 123 l-58 117 31 20 c48 32
56 57 56 195 0 203 -10 210 -287 210 l-173 0 0 -335z m268 206 c10 -1 12 -23
10 -88 l-3 -88 -47 -3 -48 -3 0 89 c0 49 2 91 5 93 4 5 42 5 83 0z"/>
<path d="M6395 4588 c-3 -7 -18 -67 -35 -133 -17 -66 -53 -206 -81 -312 -27
-105 -49 -196 -49 -202 0 -7 30 -11 84 -11 92 0 87 -4 101 68 l7 32 83 0 83 0
7 -32 c14 -72 8 -68 107 -68 74 0 89 3 85 14 -7 17 -146 553 -159 614 l-10 42
-109 0 c-79 0 -111 -4 -114 -12z m143 -323 c6 -27 13 -62 16 -77 6 -28 6 -28
-50 -28 -53 0 -56 1 -51 23 3 12 16 67 29 122 l22 100 12 -45 c6 -25 16 -67
22 -95z"/>
<path d="M6950 4538 l0 -63 60 2 60 2 0 -275 0 -274 90 0 90 0 2 273 c2 209 6
272 16 273 6 1 35 2 62 3 l50 1 0 60 0 60 -215 0 -215 0 0 -62z"/>
<path d="M7580 4265 l0 -335 205 0 205 0 0 65 0 65 -115 0 -115 0 0 75 0 75
95 0 95 0 0 60 0 60 -95 0 -96 0 3 73 3 72 95 2 c52 1 101 2 108 2 8 1 12 20
12 61 l0 60 -200 0 -200 0 0 -335z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
